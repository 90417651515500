import Head from 'next/head';
import { FC } from 'react';

import {
  IS_PRODUCTION,
  IS_STG,
  OG_IMAGE,
  SITE_DESCRIPTION,
  SITE_TITLE,
} from '@/configs';
import { assetsPath } from '@/utils/assetsPath';

type SiteHeadProps = {
  title?: string;
  description?: string;
  ogImage?: string;
  ogDescription?: string;
};

const titlePrefix = IS_STG ? '[STG]' : IS_PRODUCTION ? '' : '[DEV]';
const favicon = IS_PRODUCTION ? '/favicon.ico' : '/favicon-dev.ico';

export const SiteHead: FC<SiteHeadProps> = ({
  title,
  description,
  ogImage,
  ogDescription,
}) => {
  const pageTitle = title ?? SITE_TITLE;
  const pageDescription = description ?? SITE_DESCRIPTION;
  const pageOgDescription = ogDescription ?? pageDescription;
  const pageOgImage = ogImage ? assetsPath(ogImage) : OG_IMAGE;

  return (
    <Head>
      <title>
        {titlePrefix}
        {pageTitle}
      </title>
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta property='og:site_name' content={SITE_TITLE} />
      <meta property='og:title' content={pageTitle} />
      <meta name='description' content={pageDescription} />
      <meta property='og:description' content={pageOgDescription} />
      <meta property='og:image' content={pageOgImage} />
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:image' content={pageOgImage} />
      <meta property='apple-mobile-web-app-title' content={SITE_TITLE} />
      <link rel='icon' type='image/png' href={assetsPath(favicon)} />
      <link
        rel='apple-touch-icon'
        type='image/png'
        href={assetsPath('/favicon-48x48.ico')}
      />
    </Head>
  );
};
