import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';

import { buildPageTitle } from '@/utils/buildPageTitle';
import { getCoursePagePath } from '@/utils/getCoursePagePath';

import { SiteHead } from '@/components/common/SiteHead';

const REDIRECT_COURSE = 'webdesign' as const;

type RedirectLayoutProps = {
  title?: string;
  description?: string;
  slug?: string;
};

export const RedirectSampleCourse: FC<RedirectLayoutProps> = ({
  title,
  description,
  slug,
}) => {
  const pageTitle = buildPageTitle(title);
  const redirect = getCoursePagePath(slug ?? REDIRECT_COURSE);
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log('Preview Mode');
    setTimeout(() => {
      router
        .replace(redirect)
        .then(() => {
          router.reload();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Redirect error', error);
        });
    }, 300);
  }, [router]);

  return (
    <div>
      <SiteHead title={pageTitle} description={description} />
    </div>
  );
};
