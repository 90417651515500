import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';

import { SHElikes_TOP } from '@/configs';
import { buildPageTitle } from '@/utils/buildPageTitle';

import { SiteHead } from '@/components/common/SiteHead';

type RedirectTopLayoutProps = {
  title?: string;
  description?: string;
};

export const RedirectTopLayout: FC<RedirectTopLayoutProps> = ({
  title,
  description,
}) => {
  const pageTitle = buildPageTitle(title);

  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      router.replace(SHElikes_TOP).catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Redirect error', error);
      });
    }
  }, [router]);

  return (
    <div>
      <SiteHead title={pageTitle} description={description} />
    </div>
  );
};
