import { SITE_TITLE } from '@/configs';

export const buildPageTitle = (
  title?: string,
  isSuffix: boolean = true,
): string => {
  if (title === undefined) {
    return SITE_TITLE;
  }

  return isSuffix ? `${title} | ${SITE_TITLE}` : title;
};
